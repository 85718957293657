<template>
  <div>
    <!-- Header -->
    <HeaderFornecedor />

    <!-- Formulário de Fornecedor -->
    <div class="container mt-1">
      <FornecedorForm 
        @submit-success="openModal('success', $event)" 
        @submit-error="handleSubmitError" 
        @trigger-loading="toggleLoading(true)"
        @stop-loading="toggleLoading(false)"  
      />
    </div>

    <!-- Modal Component -->
    <Modal 
      :modalTitle="modalTitle" 
      :modalMessage="modalMessage" 
      :modalType="modalType" 
      :showOptions="showModalOptions"
      @confirm="handleConfirm"
      @trigger-loading="toggleLoading(true)"
      @stop-loading="toggleLoading(false)"
      ref="modalRef" 
    />

    <!-- Loading Component -->
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import HeaderFornecedor from './components/HeaderFornecedor.vue';
import FornecedorForm from './components/FornecedorForm.vue';
import Modal from './shared/Modal.vue';
import Loading from './shared/Loading.vue';

export default {
  name: 'App',
  components: {
    HeaderFornecedor,
    FornecedorForm,
    Modal,
    Loading
  },
  data() {
    return {
      modalTitle: '',
      modalMessage: '',
      modalType: '',
      showModalOptions: false, // Controle para exibir opções no modal
      isLoading: false, // Controle de loading
      confirmationData: null // Armazena o dado para confirmação
    };
  },
  methods: {
    openModal(type, message) {
      this.modalTitle = type === 'success' ? 'Sucesso!' : 'Erro!';
      this.modalMessage = message;
      this.modalType = type;
      this.showModalOptions = false;
      this.$refs.modalRef.showModal();
    },
    handleSubmitError(message, data = null) {
      // Verifica se o erro inclui `data` para exibir a confirmação
      if (data) {
        console.log("handleErrorData: " + data)
        this.confirmationData = data;
        this.modalTitle = 'Erro!';
        this.modalMessage = message;
        this.modalType = 'error';
        this.showModalOptions = true;
        this.$refs.modalRef.showModal();
      } else {
        this.openModal('error', message);
      }
    },
    handleConfirm() {
      this.toggleLoading(true)
      if (this.confirmationData) {
        fetch(`${window.VUE_APP_BACKEND_URL}/api/fornecedor/confirm`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: this.confirmationData }),
        })
          .then(response => {
            if (response.status === 201) {
              return response.json().then(result => {
                console.log("Confirmação enviada com sucesso:", result);
                this.openModal('success', 'Confirmação enviada com sucesso!');
                this.toggleLoading(false)
              });
            } else {
              return response.json().then(result => {
                console.error("Erro ao enviar confirmação:", result);
                this.openModal('error', `Erro ao enviar confirmação: ${result.message || 'Erro desconhecido'}`);
                this.toggleLoading(false)
              });
            }
          })
          .catch(error => {
            console.error("Erro ao enviar confirmação:", error);
            this.openModal('error', 'Erro ao enviar confirmação.');
            this.toggleLoading(false)
          });
      }
    },
    toggleLoading(status) {
      this.isLoading = status;
    }
  }
};
</script>
