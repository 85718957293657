<!-- shared/Loading.vue -->
<template>
    <div class="loading-overlay">
        <div class="loading-content">
            <div class="spinner"></div>
            <p class="loading-message">Aguarde! O processamento das informações pode levar algum tempo</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading'
    };
</script>

<style scoped>
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .loading-content {
        display: flex;
        flex-direction: column; /* Alinha o spinner e a mensagem verticalmente */
        justify-content: center;
        align-items: center;
    }

    .spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-top: 4px solid white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    .loading-message {
        margin-top: 20px; /* Espaçamento entre o spinner e a mensagem */
        color: white;
        font-size: 16px;
        text-align: center;
        max-width: 80%;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>
