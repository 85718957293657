<template>
    <header>
      <div class="image-container">
        <img src="/images/fornecedor.jpg" alt="Fornecedor">
      </div>
      <div class="header-content">
        <h1>Fornecedores</h1>
        <p>Seja nosso fornecedor e cresça com a gente! Estamos sempre em busca de novas parcerias para expandir nossa rede de serviços e produtos. Ao se cadastrar, você terá a chance de oferecer seus produtos para uma grande base de clientes, com total transparência e suporte contínuo.</p>
      </div>
    </header>
    <div class="form-header mt-5">
        <div class="form-title">Seja nosso fornecedor</div>
        <div class="form-subtitle">Preencha o formulário abaixo e aguarde nosso contato.</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HeaderFornecedor',
  };
  </script>
  
  <style scoped>
  header {
    width: 100%;
    height: 514px;
    background-color: #F3F4F6;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 50px;
  }
  
  .image-container {
    width: 431px;
    height: 285.56px;
    background-color: #D1D5DB;
    border-radius: 8px;
    border: 2px solid black;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .header-content {
    max-width: 547px;
    margin-left: 0px;
    margin-top: 0px;
  }
  
  h1 {
    font-size: 40px;
    color: black;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 16px;
    line-height: 30px;
    color: black;
    font-weight: 500;
  }

  .form-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .form-title {
    color: black;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
  }
  
  .form-subtitle {
    width: 647px;
    color: black;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 28px;
    word-wrap: break-word;
  }
  </style>
  