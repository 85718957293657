export const bancos = [
    { value: '406', name: 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '117', name: 'ADVANCED CORRETORA DE CÂMBIO LTDA' },
    { value: '272', name: 'AGK CORRETORA DE CAMBIO S.A.' },
    { value: '349', name: 'AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '313', name: 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.' },
    { value: '461', name: 'ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.' },
    { value: '188', name: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES' },
    { value: '463', name: 'AZUMI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '332', name: 'Acesso Solucoes de pagamento SA' },
    { value: '080', name: 'B&T CORRETORA DE CAMBIO LTDA.' },
    { value: '246', name: 'BANCO ABC BRASIL S.A.' },
    { value: '075', name: 'BANCO ABN AMRO S.A.' },
    { value: '121', name: 'BANCO AGIBANK S.A.' },
    { value: '025', name: 'BANCO ALFA S.A.' },
    { value: '065', name: 'BANCO ANDBANK (BRASIL) S.A.' },
    { value: '213', name: 'BANCO ARBI S.A.' },
    { value: '096', name: 'BANCO B3 S.A.' },
    { value: '024', name: 'BANCO BANDEPE S.A.' },
    { value: '330', name: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.' },
    { value: '318', name: 'BANCO BMG S.A.' },
    { value: '752', name: 'BANCO BNP PARIBAS BRASIL S.A.' },
    { value: '107', name: 'BANCO BOCOM BBM S.A.' },
    { value: '063', name: 'BANCO BRADESCARD S.A.' },
    { value: '036', name: 'BANCO BRADESCO BBI S.A.' },
    { value: '122', name: 'BANCO BRADESCO BERJ S.A.' },
    { value: '394', name: 'BANCO BRADESCO FINANCIAMENTOS S.A.' },
    { value: '237', name: 'BANCO BRADESCO S.A.' },
    { value: '378', name: 'BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA' },
    { value: '218', name: 'BANCO BS2 S.A.' },
    { value: '208', name: 'BANCO BTG PACTUAL S.A.' },
    { value: '413', name: 'BANCO BV S.A.' },
    { value: '626', name: 'BANCO C6 CONSIGNADO S.A.' },
    { value: '336', name: 'BANCO C6 S.A.' },
    { value: '473', name: 'BANCO CAIXA GERAL - BRASIL S.A.' },
    { value: '040', name: 'BANCO CARGILL S.A.' },
    { value: '266', name: 'BANCO CEDULA S.A.' },
    { value: '739', name: 'BANCO CETELEM S.A.' },
    { value: '233', name: 'BANCO CIFRA S.A.' },
    { value: '745', name: 'BANCO CITIBANK S.A.' },
    { value: '241', name: 'BANCO CLASSICO S.A.' },
    { value: '756', name: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB' },
    { value: '748', name: 'BANCO COOPERATIVO SICREDI S.A.' },
    { value: '505', name: 'BANCO CREDIT SUISSE (BRASIL) S.A.' },
    { value: '069', name: 'BANCO CREFISA S.A.' },
    { value: '222', name: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.' },
    { value: '368', name: 'BANCO CSF S.A.' },
    { value: '003', name: 'BANCO DA AMAZONIA S.A.' },
    { value: '083', name: 'BANCO DA CHINA BRASIL S.A.' },
    { value: '707', name: 'BANCO DAYCOVAL S.A.' },
    { value: '300', name: 'BANCO DE LA NACION ARGENTINA' },
    { value: '495', name: 'BANCO DE LA PROVINCIA DE BUENOS AIRES' },
    { value: '654', name: 'BANCO DIGIMAIS S.A.' },
    { value: '335', name: 'BANCO DIGIO S.A.' },
    { value: '001', name: 'BANCO DO BRASIL S.A.' },
    { value: '047', name: 'BANCO DO ESTADO DE SERGIPE S.A.' },
    { value: '034', name: 'BANCO DO ESTADO DO AMAZONAS S.A.' },
    { value: '037', name: 'BANCO DO ESTADO DO PARÁ S.A.' },
    { value: '041', name: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.' },
    { value: '004', name: 'BANCO DO NORDESTE DO BRASIL S.A.' },
    { value: '338', name: 'BANCO F. BARRETO' },
    { value: '265', name: 'BANCO FATOR S.A.' },
    { value: '224', name: 'BANCO FIBRA S.A.' },
    { value: '094', name: 'BANCO FINAXIS S.A.' },
    { value: '125', name: 'BANCO GENIAL S.A.' },
    { value: '390', name: 'BANCO GM S.A.' },
    { value: '612', name: 'BANCO GUANABARA S.A.' },
    { value: '269', name: 'BANCO HSBC S.A.' },
    { value: '999', name: 'BANCO IMPOSTOS RETIDOS' },
    { value: '012', name: 'BANCO INBURSA S.A.' },
    { value: '604', name: 'BANCO INDUSTRIAL DO BRASIL S.A.' },
    { value: '077', name: 'BANCO INTER S.A.' },
    { value: '249', name: 'BANCO INVESTCRED UNIBANCO S.A.' },
    { value: '479', name: 'BANCO ITAUBANK S.A.' },
    { value: '184', name: 'BANCO ITAÚ BBA S.A.' },
    { value: '029', name: 'BANCO ITAÚ CONSIGNADO S.A.' },
    { value: '074', name: 'BANCO J. SAFRA S.A.' },
    { value: '376', name: 'BANCO J.P. MORGAN S.A.' },
    { value: '217', name: 'BANCO JOHN DEERE S.A.' },
    { value: '076', name: 'BANCO KDB DO BRASIL S.A.' },
    { value: '757', name: 'BANCO KEB HANA DO BRASIL S.A.' },
    { value: '630', name: 'BANCO LETSBANK S.A.' },
    { value: '600', name: 'BANCO LUSO BRASILEIRO S.A.' },
    { value: '243', name: 'BANCO MASTER S/A' },
    { value: '389', name: 'BANCO MERCANTIL DO BRASIL S.A.' },
    { value: '381', name: 'BANCO MERCEDES-BENZ DO BRASIL S.A.' },
    { value: '370', name: 'BANCO MIZUHO DO BRASIL S.A.' },
    { value: '746', name: 'BANCO MODAL S.A.' },
    { value: '066', name: 'BANCO MORGAN STANLEY S.A.' },
    { value: '456', name: 'BANCO MUFG BRASIL S.A.' },
    { value: '007', name: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL' },
    { value: '151', name: 'BANCO NOSSA CAIXA S.A.' },
    { value: '079', name: 'BANCO ORIGINAL DO AGRONEGÓCIO S.A.' },
    { value: '212', name: 'BANCO ORIGINAL S.A.' },
    { value: '712', name: 'BANCO OURINVEST S.A.' },
    { value: '623', name: 'BANCO PAN S.A.' },
    { value: '611', name: 'BANCO PAULISTA S.A.' },
    { value: '380', name: 'BANCO PICPAY' },
    { value: '643', name: 'BANCO PINE S.A.' },
    { value: '747', name: 'BANCO RABOBANK INTERNATIONAL BRASIL S.A.' },
    { value: '088', name: 'BANCO RANDON S.A.' },
    { value: '356', name: 'BANCO REAL S.A.' },
    { value: '633', name: 'BANCO RENDIMENTO S.A.' },
    { value: '741', name: 'BANCO RIBEIRAO PRETO S.A.' },
    { value: '720', name: 'BANCO RNX S.A.' },
    { value: '120', name: 'BANCO RODOBENS S.A.' },
    { value: '422', name: 'BANCO SAFRA S.A.' },
    { value: '033', name: 'BANCO SANTANDER (BRASIL) S.A.' },
    { value: '743', name: 'BANCO SEMEAR S.A.' },
    { value: '276', name: 'BANCO SENFF S.A.' },
    { value: '366', name: 'BANCO SOCIETE GENERALE BRASIL S.A.' },
    { value: '637', name: 'BANCO SOFISA S.A.' },
    { value: '299', name: 'BANCO SOROCRED S.A. - BANCO MÚLTIPLO' },
    { value: '464', name: 'BANCO SUMITOMO MITSUI BRASILEIRO S.A.' },
    { value: '082', name: 'BANCO TOPÁZIO S.A.' },
    { value: '387', name: 'BANCO TOYOTA DO BRASIL S.A.' },
    { value: '634', name: 'BANCO TRIANGULO S.A.' },
    { value: '018', name: 'BANCO TRICURY S.A.' },
    //{ value: '111', name:BANCO VIRTUAL' },
    { value: '653', name: 'BANCO VOITER S.A.' },
    { value: '393', name: 'BANCO VOLKSWAGEN S.A.' },
    { value: '655', name: 'BANCO VOTORANTIM S.A.' },
    { value: '610', name: 'BANCO VR S.A.' },
    { value: '119', name: 'BANCO WESTERN UNION DO BRASIL S.A.' },
    { value: '124', name: 'BANCO WOORI BANK DO BRASIL S.A.' },
    { value: '348', name: 'BANCO XP S.A.' },
    { value: '081', name: 'BANCOSEGURO S.A.' },
    { value: '021', name: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO' },
    { value: '755', name: 'BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.' },
    { value: '268', name: 'BARI COMPANHIA HIPOTECÁRIA' },
    { value: '250', name: 'BCV - BANCO DE CRÉDITO E VAREJO S.A.' },
    { value: '144', name: 'BEXS BANCO DE CÂMBIO S/A' },
    { value: '253', name: 'BEXS CORRETORA DE CÂMBIO S/A' },
    { value: '134', name: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
    { value: '426', name: 'BIORC FINANCEIRA - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
    { value: '377', name: 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '017', name: 'BNY MELLON BANCO S.A.' },
    { value: '408', name: 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '126', name: 'BR PARTNERS BANCO DE INVESTIMENTO S.A.' },
    { value: '433', name: 'BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '070', name: 'BRB - BANCO DE BRASILIA S.A.' },
    { value: '092', name: 'BRK S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '142', name: 'BROKER BRASIL CORRETORA DE CÂMBIO LTDA.' },
    { value: '292', name: 'BS2 DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '735', name: 'Banco Neon S.A.' },
    { value: '104', name: 'CAIXA ECONOMICA FEDERAL' },
    { value: '309', name: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.' },
    { value: '465', name: 'CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '288', name: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
    { value: '324', name: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '130', name: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '159', name: 'CASA DO CRÉDITO S.A. SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR' },
    { value: '320', name: 'CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A' },
    { value: '477', name: 'CITIBANK N.A.' },
    { value: '180', name: 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
    { value: '402', name: 'COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '127', name: 'CODEPE CORRETORA DE VALORES E CÂMBIO S.A.' },
    { value: '423', name: 'COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS' },
    { value: '163', name: 'COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO' },
    { value: '136', name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI' },
    { value: '060', name: 'CONFIDENCE CORRETORA DE CÂMBIO S.A.' },
    { value: '085', name: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS' },
    { value: '403', name: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '429', name: 'CREDIARE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '452', name: 'CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '443', name: 'CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '011', name: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A' },
    { value: '342', name: 'CREDITAS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '289', name: 'DECYSEO CORRETORA DE CAMBIO LTDA.' },
    { value: '435', name: 'DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '487', name: 'DEUTSCHE BANK S.A. - BANCO ALEMAO' },
    { value: '449', name: 'DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '301', name: 'DOCK INSTITUICAO DE PAGAMENTO S.A.' },
    { value: '311', name: 'DOURADA CORRETORA DE CÂMBIO LTDA.' },
    { value: '383', name: 'EBANX INSTITUICAO DE PAGAMENTOS LTDA.' },
    { value: '395', name: 'F.D\'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '149', name: 'FACTA FINANCEIRA S.A. - CRÉDITO FINANCIAMENTO E INVESTIMENTO' },
    { value: '196', name: 'FAIR CORRETORA DE CAMBIO S.A.' },
    { value: '343', name: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.' },
    { value: '331', name: 'FRAM CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '285', name: 'FRENTE CORRETORA DE CÂMBIO LTDA.' },
    { value: '278', name: 'GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIÁRIOS S.A.' },
    { value: '364', name: 'GERENCIANET S.A.' },
    { value: '138', name: 'GET MONEY CORRETORA DE CÂMBIO S.A.' },
    { value: '064', name: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.' },
    { value: '177', name: 'GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES' },
    { value: '146', name: 'GUITTA CORRETORA DE CAMBIO LTDA.' },
    { value: '078', name: 'HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A.' },
    { value: '458', name: 'HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '448', name: 'HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '062', name: 'HIPERCARD BANCO MÚLTIPLO S.A.' },
    { value: '189', name: 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS' },
    { value: '271', name: 'IB CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '157', name: 'ICAP DO BRASIL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '132', name: 'ICBC DO BRASIL BANCO MÚLTIPLO S.A.' },
    { value: '439', name: 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '398', name: 'IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '492', name: 'ING BANK N.V.' },
    { value: '139', name: 'INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO' },
    { value: '652', name: 'ITAÚ UNIBANCO HOLDING S.A.' },
    { value: '341', name: 'ITAÚ UNIBANCO S.A.' },
    { value: '401', name: 'IUGUI IP SA' },
    { value: '451', name: 'J17 - SOCIEDADE DE CRÉDITO DIRETO S/A' },
    { value: '488', name: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION' },
    { value: '399', name: 'KIRTON BANK S.A. - BANCO MÚLTIPLO' },
    { value: '416', name: 'LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '293', name: 'LASTRO RDV DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '105', name: 'LECCA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A' },
    { value: '145', name: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.' },
    { value: '397', name: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.' },
    { value: '442', name: 'MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
    { value: '467', name: 'MASTER S/A CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS' },
    { value: '323', name: 'MERCADO PAGO' },
    { value: '358', name: 'MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '259', name: 'MONEYCORP BANCO DE CÂMBIO S.A.' },
    { value: '128', name: 'MS BANK S.A. BANCO DE CÂMBIO' },
    { value: '454', name: 'MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '191', name: 'NOVA FUTURA CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '753', name: 'NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO' },
    { value: '386', name: 'NU FINANCEIRA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '140', name: 'NU INVEST CORRETORA DE VALORES S.A.' },
    { value: '260', name: 'NU PAGAMENTOS S.A.' },
    { value: '419', name: 'NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '536', name: 'Neon Pagamentos' },
    { value: '111', name: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.' },
    { value: '319', name: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
    { value: '613', name: 'OMNI BANCO S.A.' },
    { value: '290', name: 'PAGSEGURO INTERNET S.A.' },
    { value: '254', name: 'PARANÁ BANCO S.A.' },
    { value: '326', name: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
    { value: '194', name: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
    { value: '174', name: 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '315', name: 'PI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '100', name: 'PLANNER CORRETORA DE VALORES S.A.' },
    { value: '410', name: 'PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.' },
    { value: '438', name: 'PLANNER TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '445', name: 'PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '108', name: 'PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '306', name: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
    { value: '468', name: 'PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '329', name: 'QI SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '283', name: 'RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA' },
    { value: '374', name: 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
    { value: '101', name: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
    { value: '270', name: 'SAGITUR CORRETORA DE CÂMBIO LTDA.' },
    { value: '751', name: 'SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO' },
    { value: '545', name: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A' },
    { value: '365', name: 'SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A.' },
    { value: '363', name: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '412', name: 'SOCIAL BANK BANCO MÚLTIPLO S/A' },
    { value: '425', name: 'SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '462', name: 'STARK SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '014', name: 'STATE STREET BRASIL S.A. - BANCO COMERCIAL' },
    { value: '197', name: 'STONE PAGAMENTOS S.A.' },
    { value: '404', name: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '340', name: 'SUPER PAGAMENTOS E ADMINISTRAÇÃO DE MEIOS ELETRÔNICOS S.A.' },
    { value: '307', name: 'TERRA INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '352', name: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '095', name: 'TRAVELEX BANCO DE CÂMBIO S.A.' },
    { value: '143', name: 'TREVISO CORRETORA DE CÂMBIO S.A.' },
    { value: '360', name: 'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '444', name: 'TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '131', name: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA' },
    { value: '129', name: 'UBS BRASIL BANCO DE INVESTIMENTO S.A.' },
    { value: '015', name: 'UBS BRASIL CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '460', name: 'UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A' },
    { value: '084', name: 'UNIPRIME DO BRASIL - COOPERATIVA DE CRÉDITO LTDA.' },
    { value: '373', name: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.' },
    { value: '457', name: 'UY3 SOCIEDADE DE CRÉDITO DIRETO S/A' },
    { value: '195', name: 'VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '411', name: 'VIA CERTA FINANCIADORA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTOS' },
    { value: '298', name: 'VIP\'S CORRETORA DE CÂMBIO LTDA.' },
    { value: '296', name: 'VISION S.A. CORRETORA DE CAMBIO' },
    { value: '367', name: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '310', name: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
    { value: '371', name: 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.' },
    { value: '280', name: 'WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
    { value: '414', name: 'WORK SOCIEDADE DE CRÉDITO DIRETO S.A.' },
    { value: '102', name: 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A' },
    { value: '359', name: 'ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A' },
    { value: '418', name: 'ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A' },
    { value: '407', name: 'ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
    { value: '325', name: 'ÓRAMA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
    { value: '355', name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.' }
];
