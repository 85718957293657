<template>
  <section>
    <h2 class="h5">Endereço</h2>

    <div class="col-md-12 mb-3">
      <input 
        type="text" 
        v-bind:value="endereco" 
        @input="$emit('update:endereco', $event.target.value)" 
        id="endereco" 
        class="form-control" 
        placeholder="Endereço"
        required
      />
    </div>

    <div class="row">
      <input 
        type="hidden"
        v-bind:value="ibge"
        @update:modelValue="$emit('update:ibge', $event)"
        id="ibge"
      />
      <div class="col-md-8 mb-3">
        <imask-input 
          :mask="'00000-000'" 
          v-bind:value="cep" 
          @input="handleNumericInput($event)"
          @update:modelValue="$emit('update:cep', $event)" 
          @blur="fetchCEPData"
          id="cep" 
          class="form-control" 
          placeholder="CEP" 
          :maxlength="9" 
          required
        />
      </div>

      <div class="col-md-4 mb-3">
        <input 
          type="text" 
          v-bind:value="numero" 
          @input="$emit('update:numero', $event.target.value)" 
          id="numero" 
          class="form-control" 
          placeholder="Número"
          required
        />
      </div>

      <div class="col-md-8 mb-3">
        <input 
          type="text" 
          v-bind:value="bairro" 
          @input="$emit('update:bairro', $event.target.value)" 
          id="bairro" 
          class="form-control" 
          placeholder="Bairro"
          required
        />
      </div>

      <div class="col-md-4 mb-3">
        <input 
          type="text" 
          v-bind:value="complemento" 
          @input="$emit('update:complemento', $event.target.value)" 
          id="complemento" 
          class="form-control" 
          placeholder="Complemento"
        />
      </div>

      <div class="col-md-8 mb-3">
        <input 
          type="text" 
          v-bind:value="cidade" 
          @input="$emit('update:cidade', $event.target.value)" 
          id="cidade" 
          class="form-control" 
          placeholder="Cidade"
          required
        />
      </div>

      <div class="col-md-3 mb-4">
        <SelectSearch 
          :options="estados"
          :modelValue="estado"
          @update:modelValue="$emit('update:estado', $event)"
          placeholder="Estado" 
        />
        <!--
        <select 
          v-bind:value="estado" 
          @change="$emit('update:estado', $event.target.value)" 
          id="estado" 
          class="form-select" 
          required
        >
        <option value="" disabled>Estado</option>
        <option v-for="estado in estados" :key="estado.value" :value="estado.value">{{ estado.name }}</option>
        </select>-->
      </div>
    </div>
  </section>
</template>

<script>
  import { handleNumericInput } from '../utils/utils.js';
  import { estados } from '../utils/data_estado';
  import SelectSearch from '../shared/SelectSearch.vue';

  export default {
    components: {
      SelectSearch
    },
    props: {
      endereco: String,
      cep: String,
      ibge: String,
      numero: String,
      complemento: String,
      bairro: String,
      cidade: String,
      estado: String
    },
    data() {
      return {
        estados
      };
    },
    methods: {
      handleNumericInput,
      async fetchCEPData() {
        this.$emit('trigger-loading');
        const cepValue = this.cep;
        if (cepValue && cepValue.length === 9) {
          const apiUrl = `${process.env.VUE_APP_API_CEP}/${cepValue}/json`; 
          try {
            const response = await fetch(apiUrl);
            if (!response.ok) {
              throw new Error("Erro ao consultar o CEP");
            }
            const data = await response.json();
            if (data) {
              this.$emit('update:endereco', data.logradouro);
              this.$emit('update:bairro', data.bairro);
              this.$emit('update:cidade', data.localidade);
              this.$emit('update:estado', data.uf);
              this.$emit('update:ibge', data.ibge)
            }
            if (data.erro) {
              this.$emit('cep-invalido', 'CEP não encontrado ou inválido, informe o endereço manualmente')
            }
            this.$emit('stop-loading');
          } catch (error) {
            console.error("Erro ao buscar informações do CEP:", error);
            this.$emit('stop-loading');
          }
        }
        this.$emit('stop-loading');
      }
    }
  };
</script>
