<template>
  <section>
    <h2 class="h5">Dados bancários</h2>
    <div class="mb-3">
      <label class="form-label">Método de Pagamento:</label>
      <!-- Mostrar somente Transferência se tipoDocumento for CPF -->
      <div v-if="tipoDocumento === 'cpf'">
        <div class="form-check">
          <input 
            type="radio" 
            v-model="localMetodoPagamento" 
            value="transferencia" 
            id="transferencia" 
            class="form-check-input custom-radio"
            required
          />
          <label for="transferencia" class="form-check-label">Transferência</label>
        </div>
      </div>
      <!-- Mostrar todas as opções para outros tipos de documento -->
      <div v-else>
        <div class="form-check">
          <input 
            type="radio" 
            v-model="localMetodoPagamento" 
            value="boleto" 
            id="boleto" 
            class="form-check-input custom-radio"
            required
          />
          <label for="boleto" class="form-check-label">Boleto</label>
        </div>
        <div class="form-check">
          <input 
            type="radio" 
            v-model="localMetodoPagamento" 
            value="transferencia" 
            id="transferencia" 
            class="form-check-input custom-radio" 
          />
          <label for="transferencia" class="form-check-label">Transferência</label>
        </div>
      </div>
    </div>

    <!-- Dados Bancários Condicional -->
    <div v-if="localMetodoPagamento === 'transferencia'">
      <h3 class="h6">Dados da Conta Bancária</h3>
      <div class="row">
        <div class="col-md-9 mb-3">
          <SelectSearch 
            :options="formattedBancos"
            @update:modelValue="$emit('update:banco', $event)"
            :modelValue="banco"
            placeholder="Banco" />
        </div>

        <div class="col-md-3 mb-3">
          <select v-bind:value="tipoConta" @change="$emit('update:tipoConta', $event.target.value)" id="tipoConta" class="form-select">
            <option value="corrente">Corrente</option>
            <option value="poupanca">Poupança</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 mb-3">
          <input 
            type="number" 
            v-bind:value="agencia" 
            @input="$emit('update:agencia', $event.target.value)" 
            id="agencia" 
            class="form-control" 
            placeholder="Agência"
            required
          />
        </div>
        <div class="col-md-7 mb-3 d-flex align-items-center">
          <input 
            type="number" 
            v-bind:value="numeroConta" 
            @input="$emit('update:numeroConta', $event.target.value)" 
            id="numeroConta" 
            class="form-control" 
            placeholder="Número da conta" 
            required 
          />
          <span class="mx-2">-</span>
          <input 
            type="number" 
            v-bind:value="digitoVerificador" 
            @input="$emit('update:digitoVerificador', $event.target.value)" 
            id="digitoVerificador" 
            class="form-control" 
            placeholder="Dígito"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { bancos } from '../utils/data_banco';
import SelectSearch from '../shared/SelectSearch.vue';

export default {
  components: {
    SelectSearch
  },
  props: {
    metodoPagamento: String,
    banco: String,
    tipoConta: String,
    agencia: String,
    numeroConta: String,
    digitoVerificador: String,
    tipoDocumento: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      bancos
    };
  },
  computed: {
    formattedBancos() {
      return this.bancos.map(banco => ({
        ...banco,
        name: `${banco.value} - ${banco.name.replace(/^\d+\s-\s/, '')}` 
      }));
    },
    localMetodoPagamento: {
      get() {
        return this.metodoPagamento;
      },
      set(value) {
        this.$emit('update:metodoPagamento', value);
      }
    }
  }
}
</script>
