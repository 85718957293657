<template>
    <section>
      <h2 class="h5">Contato</h2>
      <div class="row">
        <div class="col-md-2 mb-3">
            <imask-input 
                :mask="'00'" 
                v-bind:value="ddd" 
                @input="handleNumericInput($event)"
                @update:modelValue="$emit('update:ddd', $event)" 
                id="ddd" 
                class="form-control" 
                placeholder="DDD"
                :maxlength="2"
                required
            />
        </div>
  
        <div class="col-md-6 mb-3">
          <imask-input
            v-bind:value="telefone"
            @input="handleNumericInput($event)"
            @update:modelValue="$emit('update:telefone', $event)"
            :mask="'00000-0000'"
            id="telefone"
            class="form-control"
            placeholder="Telefone"
            :maxlength="10"
            required
          />
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { handleNumericInput } from '../utils/utils.js';
  export default {
    props: {
      ddd: String,
      telefone: String
    },
    methods: {
        handleNumericInput
    }
  }
  </script>