import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/styles.css';
import IMask from 'imask';

const app = createApp(App);

app.component('imask-input', {
  props: ['mask', 'modelValue'],
  template: `<input :value="modelValue" @input="update($event)" />`,
  methods: {
    update(event) {
      const masked = IMask.createMask({ mask: this.mask });
      masked.resolve(event.target.value);
      this.$emit('update:modelValue', masked.value);
    }
  }
});

app.mount('#app');
