<template>
    <div class="select-search">
      <input
        type="text"
        v-model="searchQuery"
        :placeholder="placeholder"
        class="search-input"
        @input="filterOptions"
        @focus="showDropdown = true"
        @blur="hideDropdown"
      />
      <ul v-if="showDropdown" class="options-list">
        <li
          v-for="option in filteredOptions"
          :key="option.value"
          @mousedown.prevent="selectOption(option)"
          class="option-item"
        >
          {{ option.name }}
        </li>
        <li v-if="filteredOptions.length === 0" class="no-results">Nenhuma opção encontrada</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: 'Pesquisar...'
      }
    },
    data() {
      return {
        searchQuery: '',
        filteredOptions: this.options,
        showDropdown: false
      };
    },
    watch: {
      modelValue(newValue) {
        this.updateQueryFromValue(newValue);
      }
    },
    methods: {
      filterOptions() {
        const query = this.searchQuery.toLowerCase();
        this.filteredOptions = this.options.filter(option =>
          option.name.toLowerCase().includes(query)
        );
      },
      selectOption(option) {
        this.$emit('update:modelValue', option.value); // Emite o valor correto para o pai
        this.searchQuery = option.name;
        this.showDropdown = false;
      },
      updateQueryFromValue(value) {
        const selectedOption = this.options.find(option => option.value === value);
        this.searchQuery = selectedOption ? selectedOption.name : '';
      },
      hideDropdown() {
        setTimeout(() => {
          this.showDropdown = false;
        }, 200);
      }
    },
    mounted() {
      this.updateQueryFromValue(this.modelValue);
    }
  };
  </script>
  
  <style scoped>
  .select-search {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .search-input {
    width: 100%;
    padding: 6px;
    border-radius: 6px;
    border: .5px solid #D1D5DB;
    font-family: 'Montserrat', sans-serif;
    color: #374151;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath fill='red' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 30px;
  }
  
  .search-input:focus {
    outline: none;
    border: .5px solid #FF0000;
    box-shadow: 0 0 0 1px #FF0000;
  }
  
  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 10;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .option-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .option-item:hover {
    background-color: #f1f1f1;
  }
  
  .no-results {
    padding: 8px;
    color: #888;
  }
  </style>
  